import { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import DataAnalysisIcon from 'svgs/single-case-study/data-analysis.svg';
import PythonIcon from 'svgs/single-case-study/python.svg';
import RIcon from 'svgs/single-case-study/r.svg';

import { contentResolver } from '../utilities';

const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.q1`,
        answer: `${faqPath}.a1`,
    },
    {
        question: `${faqPath}.q2`,
        answer: `${faqPath}.a2`,
    },
    {
        question: `${faqPath}.q3`,
        answer: `${faqPath}.a3`,
    },
    {
        question: `${faqPath}.q4`,
        answer: `${faqPath}.a4`,
    },
    {
        question: `${faqPath}.q5`,
        answer: `${faqPath}.a5`,
    },
];

const content = contentResolver({
    project: 'salesPredictiveAlgorithm',
    brandColor: '--sales-predictive-algorithm-case-study',
    scopeOfWork: [
        {
            title: 'scopeOfWork.dataAnalysis',
            Icon: DataAnalysisIcon,
        },
        {
            title: 'scopeOfWork.r',
            Icon: RIcon,
        },
        {
            title: 'scopeOfWork.python',
            Icon: PythonIcon,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
    },
    paragraphsCounters: {
        header: 1,
        about: 2,
        challenge: 3, // number of paragraphs in the challenge section
        mainGoals: 2,
        solutions: 1,
        summary: 1,
    },
    headerWrapperCss: css`
        width: 100%;
    `,
    headerFirstCss: css`
        width: 80%;
    `,
    caseStudies: ['nomisSolutions', 'moneyTransferApplication', 'churn'],
    caseStudiesSubtitle: 'case-studies.subtitle',
    faqTitle: 'faq-section.title',
    faqData: FAQ_ITEMS,
});

content.headerImageAlt = 'custom sales prediction software';
content.about.alt = 'custom sales prediction app';
content.challenge.alt = 'custom sales prediction application';
content.mainGoals.alt =
    'custom sales prediction software development app development';
content.solutions.alt = 'custom sales prediction machine learning model';
content.summary.alt = 'sales prediction machine learning model';

export const useSalesPredictiveAlgorithm = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/sales-predictive-algorithm/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/sales-predictive-algorithm/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/sales-predictive-algorithm/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/sales-predictive-algorithm/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/sales-predictive-algorithm/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/sales-predictive-algorithm/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
